import type { Metadata } from 'next';

export const configs = {
  baseURL: process.env.BASE_URL,
  endpointDeveloper: process.env.ENDPOINT_DEVELOPERS,
  socketUrlMidjourney: process.env.SOCKET_URL_MIDJOURNEY,
  socketUrl: process.env.SOCKET_URL,
  ipfsGatewayUrl: process.env.IPFS_GATEWAY_URL,
  ssoUrl: process.env.SSO_URL,
  ssoCallback: process.env.SSO_CALLBACK,
  balanceApiSuffix: process.env.BALANCE_API_SUFFIX,
  airiTokenContract: process.env.AIRI_TOKEN_CONTRACT ?? '',
  airiTokenContractBsc: process.env.AIRI_TOKEN_CONTRACT_BSC ?? '',
  usdtTokenContract: process.env.USDT_TOKEN_CONTRACT ?? '',
  cupiTokenContract: process.env.CUPI_TOKEN_CONTRACT,
  lcdURL: process.env.LCD_URL,
  rpcURL: process.env.RPC_URL,
  marketPlace721Contract: process.env.MARKET_PLACE_721_CONTRACT,
  nftTokenContract: process.env.NFT_TOKEN_CONTRACT,
  lockContractAddr: process.env.LOCK_CONTRACT_ADDR ?? '',
  lockContractAddrBsc: process.env.LOCK_CONTRACT_ADDR_BSC ?? '',
  auctionContract: process.env.AUCTION_CONTRACT,
  nft1155TokenContract: process.env.NFT_1155_TOKEN_CONTRACT,
  marketPlace1155Contract: process.env.MARKET_PLACE_1155_CONTRACT,
  scanAPI: process.env.SCAN_API,
  scanURL: process.env.SCAN_URL,
  marketPlaceContract: process.env.MARKET_PLACE_CONTRACT ?? '',
  sentryDns: process.env.SENTRY_DNS,
  twitterClientID: process.env.TWITTER_CLIENT_ID,
  discordClientID: process.env.DISCORD_CLIENT_ID,
  grantAddress: process.env.GRANT_ADDRESS,
  freeToken: process.env.FREE_TOKEN,
  chainIdBsc: process.env.CHAIN_ID_BSC ?? '56',
  discordClientIdCampaign: process.env.DISCORD_CLIENT_ID_CAMPAIGN,
  migrateStakeAiri: process.env.MIGRATE_STAKE_AIRI,
};

const AIRIGHT_SEO_IMAGE = 'https://data.airight.io/home/seo/thumbnail_104.jpg';
const META_DATA_BASE = configs.ssoCallback || 'https://airight.io';

export const airightSeo: Metadata = {
  title: 'aiRight: Curated Generative AI for Everyone!',
  metadataBase: new URL(META_DATA_BASE),
  generator: 'aiRight',
  creator: 'Oraichain Labs',
  publisher: 'Oraichain Labs',
  description:
    'aiRight is the world-first Web UI platform empowering anyone to create with AI models & a massive data library. Safeguard your copyright on-chain - all without coding. Free your creativity!',
  keywords: [
    'AI',
    'aiRight',
    'Prompt',
    'Midjourney',
    'Anime',
    'QR Code',
    'ControlNet',
    'Stable Diffusion ',
  ],
  openGraph: {
    title: 'aiRight. FREE Generative-AI platform & hosting site!',
    description:
      'aiRight is the world-first Web UI platform empowering anyone to create with AI models & a massive data library. Safeguard your copyright on-chain - all without coding. Free your creativity!',
    url: configs.ssoCallback,
    siteName: 'aiRight',
    images: [AIRIGHT_SEO_IMAGE],
    locale: 'en_US',
    type: 'website',
  },
  twitter: {
    card: 'summary',
    title: 'aiRight. FREE Generative-AI platform & hosting site!',
    description:
      'aiRight is the world-first Web UI platform empowering anyone to create with AI models & a massive data library. Safeguard your copyright on-chain - all without coding. Free your creativity!',
    creator: 'Oraichain Labs',
    images: [AIRIGHT_SEO_IMAGE],
  },
  manifest: '/manifest.json',
  themeColor: '#ffffff',
};
